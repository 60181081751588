import { Timestamp } from '@bufbuild/protobuf';
import {
  ListMetricsRequest_AggregationType,
  ListMetricsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { aqoraClient } from '~/services/api/aqora-client';

type DateRange = [Date, Date];

export const listRainwaterKpis = async (
  systemName: string,
  [fromDate, endDate]: DateRange,
): Promise<ListMetricsResponse> =>
  await aqoraClient.listMetrics({
    resourceNames: [systemName],
    types: [MetricType.KPI_PLANT_USAGE_L, MetricType.KPI_RAIN_TOTAL_L],
    aggregation: ListMetricsRequest_AggregationType.ACCUMULATE,
    rangeFrom: Timestamp.fromDate(fromDate),
    rangeTo: Timestamp.fromDate(endDate),
  });

export const getWaterStoredKpi = async (
  systemName: string,
): Promise<ListMetricsResponse> => {
  const now = new Date();
  const pastWeek = new Date(now.setDate(now.getDate() - 7));

  return await aqoraClient.listMetrics({
    resourceNames: [systemName],
    types: [MetricType.KPI_WATER_STORED_L],
    aggregation: ListMetricsRequest_AggregationType.MOST_RECENT_ONLY,
    rangeFrom: Timestamp.fromDate(pastWeek),
    rangeTo: Timestamp.fromDate(new Date()),
  });
};
