import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { listSystemUsersQuery } from '~/pages/SystemUsers/api/systemUsers.queries';
import { SystemUsersModel } from '~/pages/SystemUsers/models/SystemUsersModel';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { requireUserPermissionForSystemId } from '~/shared/auth/auth-utils';
import { createSystemUsersRoute } from '~/shared/models/create-routes';
import { nameFromId } from '~/shared/models/id-utils';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';

export type SystemUsersLoaderData = {
  systemUsers: SystemUsersModel;
};

export const systemUsersLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemUsersLoaderData | Response> => {
    const { systemId } = params;

    const listSystemsReponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsReponse);

    if (!systemId) {
      const redirectUrl = createSystemUsersRoute(
        listSystemsModel.systems[0].id,
      );

      return redirect(redirectUrl);
    }

    await requireUserPermissionForSystemId(
      queryClient,
      request,
      SystemPermission_Permission.USER_MANAGEMENT,
      systemId,
    );

    const systemUsers = await queryClient.ensureQueryData(
      listSystemUsersQuery(nameFromId(systemId)),
    );

    return { systemUsers };
  };
