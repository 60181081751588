import { Timestamp } from '@bufbuild/protobuf';
import { QueryClient } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import dayjs from 'dayjs';
import { LoaderFunctionArgs } from 'react-router-dom';
import { getReservoirDynamicInfoQuery } from '~/pages/ReservoirDynamicInfo/api/resevoirDynamicInfo.queries';
import { DYNAMIC_INFO_PREDICTION_TIME_PARAM } from '~/pages/ReservoirDynamicInfo/dynamic-info-page-consts';
import { ReservoirDynamicInfoListModel } from '~/pages/ReservoirDynamicInfo/models/ReservoirDynamicInfoListModel';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { requireUserPermissionForSystemId } from '~/shared/auth/auth-utils';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export type ReservoirDynamicInfoLoader = {
  reservoirDynamicInfo: ReservoirDynamicInfoListModel;
};

export function resevoirDynamicInfoLoader(queryClient: QueryClient) {
  return async ({
    request,
    params,
  }: LoaderFunctionArgs): Promise<ReservoirDynamicInfoLoader> => {
    const { systemId, reservoirId } = params;
    assertIsDefined(systemId);
    assertIsDefined(reservoirId);

    await requireUserPermissionForSystemId(
      queryClient,
      request,
      SystemPermission_Permission.ADMIN,
      systemId,
    );

    const listSystemsResponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    const reservoir = system.reservoirById(reservoirId);

    assertIsDefined(reservoir);

    const { searchParams } = new URL(request.url);

    const time = searchParams.get(DYNAMIC_INFO_PREDICTION_TIME_PARAM);

    let timestamp;

    if (time) {
      timestamp = Timestamp.fromDate(dayjs(time).toDate());
    }

    const reservoirDynamicInfo = await queryClient.ensureQueryData(
      getReservoirDynamicInfoQuery(reservoir.name, timestamp),
    );

    return { reservoirDynamicInfo };
  };
}
