import { QueryClient } from '@tanstack/react-query';
import { ListSystemsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { LoaderFunctionArgs } from 'react-router-dom';
import { listMetricsQuery } from '~/shared/api/metrics.queries';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { nameFromId } from '~/shared/models/id-utils';
import { MetricsModel } from '~/shared/models/metrics/MetricsModel';
import { toMetricTypeSet } from './toMetricTypeSet';
import { MetricDataSet } from './types';
import { METRICS_DATA_PARAM_NAME } from '~/shared/models/create-routes';
import { assertIsDefined } from '~/types/assert-type';
import { checkAuthorization } from '~/shared/auth/auth-utils';

export type MetricsSystemLoaderResult = {
  systems: ListSystemsResponse;
};

export function metricsSystemsLoader(queryClient: QueryClient) {
  return async ({
    request,
  }: LoaderFunctionArgs): Promise<MetricsSystemLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const listSystemsData = await queryClient.ensureQueryData(listSystemsQuery);

    return { systems: listSystemsData };
  };
}

export type MetricsLoaderResult = { metrics: (MetricsModel | undefined)[] };

export function metricsLoader(queryClient: QueryClient) {
  return async ({
    request,
    params,
  }: LoaderFunctionArgs): Promise<MetricsLoaderResult> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;

    assertIsDefined(systemId);

    const { searchParams } = new URL(request.url);

    const data = JSON.parse(
      decodeURIComponent(searchParams.get(METRICS_DATA_PARAM_NAME) || ''),
    ) as MetricDataSet;

    const promises = data.map(({ deviceId, metricType }) => {
      if (metricType) {
        return queryClient.ensureQueryData(
          listMetricsQuery({
            deviceName: nameFromId(deviceId),
            metricTypes: toMetricTypeSet(metricType),
          }),
        );
      }

      return undefined;
    });

    return { metrics: await Promise.all(promises) };
  };
}
