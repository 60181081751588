import { ListUsersForSystemResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { SystemPermission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { aqoraClient } from '~/services/api/aqora-client';

export const listSystemUsers = (
  systemName: string,
): Promise<ListUsersForSystemResponse> =>
  aqoraClient.listUsersForSystem({
    systemName,
  });

export const linkUserToSystem = ({
  userEmail,
  systemPermissions,
}: {
  userEmail: string;
  systemPermissions: SystemPermission;
}) =>
  aqoraClient.linkUserToSystemByEmail({
    userEmail,
    systemPermissions,
  });

export const upsertUserSystemRoles = ({
  name,
  systemPermissions,
}: {
  name: string;
  systemPermissions: SystemPermission[];
}) =>
  aqoraClient.upsertUserSystemRoles({
    name,
    systemPermissions,
  });
