import { ListUsersForSystemResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { SystemUserModel } from '~/pages/SystemUsers/models/SystemUserModel';

export class SystemUsersModel {
  readonly users: SystemUserModel[];

  constructor(listSystemUsersResponse: ListUsersForSystemResponse) {
    this.users = listSystemUsersResponse.users.map(
      (user) => new SystemUserModel(user),
    );
  }
}
