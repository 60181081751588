import { Command } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/command_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import {
  Peripheral,
  PeripheralAttributes,
  PeripheralAttributes_Editable,
  PeripheralAttributes_RainSensor,
  PeripheralAttributes_TapActuator,
  PeripheralAttributes_TemperatureSensor,
  PeripheralAttributes_ValveActuator,
  PeripheralAttributes_WaterHeightSensor,
  Peripheral_Type,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { TFunction } from 'i18next';
import { PeripheralFormValues } from '~/shared/models/system/PeripheralFormTypes';

const parseFloatIfDefined = (value: string | undefined) =>
  value ? parseFloat(value) : undefined;

type PeripheralFormField = {
  name: keyof PeripheralFormValues;
  label: string;
  unit: string;
  hidden: boolean;
  disabled: boolean;
};
export class PeripheralModel {
  readonly type: Peripheral_Type;

  readonly name: string;

  readonly openValveCommand: Command | undefined;

  readonly closeValveCommand: Command | undefined;

  readonly openTapCommand: Command | undefined;

  readonly closeTapCommand: Command | undefined;

  readonly setValveHeightCommand: Command | undefined;

  readonly availableMetricTypes: MetricType[];

  readonly attributes: PeripheralAttributes['attributes'] | undefined;

  constructor(peripheral: Peripheral) {
    this.type = peripheral.type;
    this.name = peripheral.name;
    this.availableMetricTypes = peripheral.availableMetricTypes;
    const commandByName = PeripheralModel.createCommandByName(
      peripheral.availableCommands,
    );
    this.openValveCommand = commandByName.get('openValve');
    this.closeValveCommand = commandByName.get('closeValve');
    this.openTapCommand = commandByName.get('openTap');
    this.closeTapCommand = commandByName.get('closeTap');
    this.setValveHeightCommand = commandByName.get('setValveHeight');
    this.attributes = peripheral.attributes?.attributes;
  }

  private static createCommandByName(
    availableCommands: Command[],
  ): Map<Command['payload']['case'], Command> {
    const result = new Map();
    availableCommands.forEach((command) =>
      result.set(command.payload.case, command),
    );
    return result;
  }

  private toApiAttributes(
    formValues: PeripheralFormValues,
  ): PeripheralAttributes['attributes'] {
    switch (this.attributes?.case) {
      case 'valveActuator':
        return {
          case: 'valveActuator',
          value: new PeripheralAttributes_ValveActuator({
            valveSize: parseFloatIfDefined(formValues.valveSize),
          }),
        };
      case 'waterHeightSensor':
        return {
          case: 'waterHeightSensor',
          value: new PeripheralAttributes_WaterHeightSensor({
            sensorOffset: parseFloatIfDefined(formValues.sensorOffset),
            sensorRange: parseFloatIfDefined(formValues.sensorRange),
            polderLevel: parseFloatIfDefined(formValues.polderLevel),
          }),
        };
      case 'tapActuator':
        return {
          case: 'tapActuator',
          value: new PeripheralAttributes_TapActuator({
            flowRate: parseFloatIfDefined(formValues.flowRate),
          }),
        };
      case 'temperatureSensor':
        return {
          case: 'temperatureSensor',
          value: new PeripheralAttributes_TemperatureSensor({
            busAddress: this.attributes.value.busAddress,
          }),
        };
      case 'rainSensor':
        return {
          case: 'rainSensor',
          value: new PeripheralAttributes_RainSensor({
            bucketSize: parseFloatIfDefined(formValues.bucketSize),
          }),
        };
      default:
        throw new Error('Unspecified peripheral type');
    }
  }

  toApiRequest(formValues: PeripheralFormValues): PeripheralAttributes {
    return new PeripheralAttributes({
      attributes: this.toApiAttributes(formValues),
    });
  }

  asFormFields(t: TFunction): PeripheralFormField[] {
    const getFields = (): PeripheralFormField[] => {
      switch (this.attributes?.case) {
        case 'valveActuator':
          return [
            {
              name: 'valveSize',
              label: t('systemDesign.product.edit.fields.labels.valveSize'),
              unit: 'mm',
              hidden:
                this.attributes.value.valveSizeEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.valveSizeEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
          ];
        case 'waterHeightSensor':
          return [
            {
              name: 'sensorOffset',
              label: t('systemDesign.product.edit.fields.labels.sensorOffset'),
              unit: 'mm',
              hidden:
                this.attributes.value.sensorOffsetEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.sensorOffsetEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
            {
              name: 'sensorRange',
              label: t('systemDesign.product.edit.fields.labels.sensorRange'),
              unit: 'mm',
              hidden:
                this.attributes.value.sensorRangeEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.sensorRangeEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
            {
              name: 'polderLevel',
              label: t('systemDesign.product.edit.fields.labels.polderLevel'),
              unit: 'mm',
              hidden:
                this.attributes.value.polderLevelEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.polderLevelEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
          ];
        case 'tapActuator':
          return [
            {
              name: 'flowRate',
              label: t('systemDesign.product.edit.fields.labels.flowRate'),
              unit: 'l/h',
              hidden:
                this.attributes.value.flowRateEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.flowRateEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
          ];
        case 'temperatureSensor':
          return [
            {
              name: 'busAddress',
              label: t('systemDesign.product.edit.fields.labels.busAddress'),
              unit: '',
              hidden:
                this.attributes.value.busAddressEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.busAddressEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
          ];
        case 'rainSensor':
          return [
            {
              name: 'bucketSize',
              label: t('systemDesign.product.edit.fields.labels.bucketSize'),
              unit: 'mm',
              hidden:
                this.attributes.value.bucketSizeEditable ===
                PeripheralAttributes_Editable.FALSE,
              disabled:
                this.attributes.value.bucketSizeEditable ===
                PeripheralAttributes_Editable.VIEW_ONLY,
            },
          ];
        default:
          throw new Error('Unspecified peripheral type');
      }
    };

    return getFields().filter((field) => !field.hidden);
  }

  asFormValues(): Partial<PeripheralFormValues> {
    switch (this.attributes?.case) {
      case 'valveActuator':
        return {
          valveSize: this.attributes.value.valveSize.toString(),
        };
      case 'waterHeightSensor':
        return {
          sensorOffset: this.attributes.value.sensorOffset.toString(),
          sensorRange: this.attributes.value.sensorRange.toString(),
          polderLevel: this.attributes.value.polderLevel.toString(),
        };
      case 'tapActuator':
        return {
          flowRate: this.attributes.value.flowRate.toString(),
        };
      case 'temperatureSensor':
        return {
          busAddress: this.attributes.value.busAddress.toString(),
        };
      case 'rainSensor':
        return {
          bucketSize: this.attributes.value.bucketSize.toString(),
        };
      default:
        throw new Error('Unspecified peripheral type');
    }
  }
}
