import { PartialMessage } from '@bufbuild/protobuf';
import {
  Reservoir,
  Reservoir_GreenType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { TFunction } from 'i18next';
import { roundToNDecimals } from '~/shared/models/metrics/metric-conversions';
import { WaterLevelsModel } from '~/shared/models/metrics/waterLevelsModel/WaterLevelsModel';
import { ProductInfo, ProductModel } from '~/shared/models/system/ProductModel';
import { ReservoirFormValues } from '~/shared/models/system/ReservoirFormTypes';
import {
  asFloat,
  asInt,
  assertIsDefined,
  assertUnreachable,
} from '~/types/assert-type';

type ProductContext = {
  previousProductName: string;
  nextProductName: string;
};

export type ReservoirParameterDetails = {
  title: string;
  value: string | number;
  unit?: string;
  redirect?: boolean;
  big?: boolean;
};

export type ReservoirType =
  | 'blue_roof'
  | 'green_roof'
  | 'tank'
  | 'infiltration_tank'
  | 'pond'
  | 'wadi';

type ReservoirParameter =
  | keyof Pick<
      ReservoirFormValues,
      | 'area'
      | 'areaGreen'
      | 'factorHollow'
      | 'greenType'
      | 'drainageSpeed'
      | 'maxWaterHeight'
      | 'minWaterHeight'
      | 'pondNapOffset'
      | 'targetWaterHeight'
      | 'targetWaterHeightGrowingMonths'
      | 'type'
      | 'wadiMaxVolume'
    >
  | 'currentWaterLevel'
  | 'reservoirCapacityUsed';

type ReservoirProperties<
  T extends ReservoirType,
  TProperties extends Object,
> = {
  type: T;
} & TProperties;

export type ReservoirTypeProperties =
  | ReservoirProperties<'blue_roof', {}>
  | ReservoirProperties<
      'green_roof',
      {
        areaGreen: number;
        greenType: Reservoir_GreenType;
      }
    >
  | ReservoirProperties<'tank', {}>
  | ReservoirProperties<
      'infiltration_tank',
      {
        drainageSpeed: number;
      }
    >
  | ReservoirProperties<
      'pond',
      {
        napOffset: number;
      }
    >
  | ReservoirProperties<
      'wadi',
      {
        maxVolume: number;
      }
    >;

export class ReservoirModel {
  readonly title: string;

  readonly id: string;

  readonly name: string;

  readonly maxWaterHeightMm: number;

  private minWaterHeightMm: number;

  private targetWaterHeightMm: number;

  private targetWaterHeightGrowingMonthsMm: number;

  private factorHollow: number;

  readonly products: ProductModel[];

  readonly sideLengthBlue: number;

  readonly areaBlueM2: number = 0;

  readonly type: ReservoirType;

  private typeProperties: ReservoirTypeProperties;

  readonly uiPosition: { x: number; y: number };

  constructor(
    reservoir: Reservoir,
    productInfos: ProductInfo[],
    productContext: ProductContext | undefined,
  ) {
    this.id = ReservoirModel.extractReservoirId(reservoir.name);
    this.title = reservoir.title;
    this.name = reservoir.name;
    this.maxWaterHeightMm = Number(reservoir.maxWaterHeightMm);

    this.minWaterHeightMm = Number(reservoir.minWaterHeightMm);

    this.targetWaterHeightMm = reservoir.targetWaterHeight;

    this.targetWaterHeightGrowingMonthsMm =
      reservoir.targetWaterHeightGrowingMonths;

    this.factorHollow = Math.round(reservoir.factorHollow * 100);

    this.sideLengthBlue = Math.sqrt(Number(reservoir.areaM2));

    this.areaBlueM2 = Number(reservoir.areaM2);

    this.typeProperties = ReservoirModel.createTypeProperties(
      reservoir.typeProperties,
    );

    this.type = this.typeProperties.type;

    assertIsDefined(reservoir.uiPosition);

    this.uiPosition = reservoir.uiPosition;

    this.products = productInfos.map((productInfo, productIndex) => {
      assertIsDefined(productContext);
      const previousProductName = ReservoirModel.getPreviousProductName(
        productIndex,
        productInfos,
        productContext,
      );
      const nextProductName = ReservoirModel.getNextProductName(
        productIndex,
        productInfos,
        productContext,
      );

      return new ProductModel({
        ...productInfo,
        nextProductName,
        previousProductName,
        reservoir: this,
      });
    });
  }

  get areaGreenM2() {
    return this.typeProperties.type === 'green_roof'
      ? this.typeProperties.areaGreen
      : 0;
  }

  static extractReservoirId(name: Reservoir['name']): string {
    const regex = /\/reservoirs\/([^/]+)/;
    const match = name.match(regex);

    assertIsDefined(match);
    return match[1];
  }

  private static createTypeProperties(
    typeProperties: Reservoir['typeProperties'],
  ): ReservoirTypeProperties {
    const typeCase = typeProperties.case;
    switch (typeCase) {
      case 'blueRoof':
        return {
          type: 'blue_roof',
        };

      case 'greenRoof':
        return {
          type: 'green_roof',
          areaGreen: typeProperties.value.areaGreen,
          greenType: typeProperties.value.greenType,
        };
      case 'tank':
        return {
          type: 'tank',
        };
      case 'infiltrationTank':
        return {
          type: 'infiltration_tank',
          drainageSpeed: roundToNDecimals(
            typeProperties.value.drainageSpeed,
            2,
          ),
        };
      case 'pond':
        return {
          type: 'pond',
          napOffset: typeProperties.value.napOffset,
        };
      case 'wadi':
        return {
          type: 'wadi',
          maxVolume: typeProperties.value.maxVolume,
        };
      case 'sewer':
        throw new Error('Reservoir type sewer not expected');
      case undefined:
        throw new Error('Expected a reservoir type, but got undefined');
      default:
        return assertUnreachable(typeCase);
    }
  }

  private static getNextProductName(
    productIndex: number,
    productInfos: ProductInfo[],
    productContext: ProductContext,
  ) {
    return productIndex === productInfos.length - 1
      ? productContext.nextProductName
      : productInfos[productIndex + 1].product.name;
  }

  private static getPreviousProductName(
    productIndex: number,
    productInfos: ProductInfo[],
    productContext: ProductContext,
  ) {
    return productIndex === 0
      ? productContext.previousProductName
      : productInfos[productIndex - 1].product.name;
  }

  private static formValuesToApiTypeProperties(
    formValues: ReservoirFormValues,
  ): NonNullable<PartialMessage<Reservoir>['typeProperties']> {
    switch (formValues.type) {
      case 'blue_roof':
        return {
          case: 'blueRoof',
          value: {},
        };

      case 'green_roof':
        return {
          case: 'greenRoof',
          value: {
            areaGreen: asFloat(formValues.areaGreen),
            greenType: asInt(formValues.greenType),
          },
        };
      case 'tank':
        return {
          case: 'tank',
          value: {},
        };
      case 'infiltration_tank':
        return {
          case: 'infiltrationTank',
          value: {
            drainageSpeed: asFloat(formValues.drainageSpeed),
          },
        };
      case 'pond':
        return {
          case: 'pond',
          value: {
            napOffset: asFloat(formValues.pondNapOffset),
          },
        };
      case 'wadi':
        return {
          case: 'wadi',
          value: {
            maxVolume: asFloat(formValues.wadiMaxVolume),
          },
        };
      default:
        return assertUnreachable(formValues.type);
    }
  }

  asFormValues(): ReservoirFormValues {
    return {
      area: this.areaBlueM2.toString(),
      type: this.typeProperties.type,
      areaGreen:
        this.typeProperties.type === 'green_roof'
          ? this.typeProperties.areaGreen.toString()
          : '',
      factorHollow: this.factorHollow.toString(),
      greenType:
        this.typeProperties.type === 'green_roof'
          ? this.typeProperties.greenType.toString()
          : '',
      drainageSpeed:
        this.typeProperties.type === 'infiltration_tank'
          ? this.typeProperties.drainageSpeed.toString()
          : '',
      maxWaterHeight: this.maxWaterHeightMm.toString(),
      minWaterHeight: this.minWaterHeightMm.toString(),
      pondNapOffset:
        this.typeProperties.type === 'pond'
          ? this.typeProperties.napOffset.toString()
          : '',
      targetWaterHeight: this.targetWaterHeightMm.toString(),
      targetWaterHeightGrowingMonths:
        this.targetWaterHeightGrowingMonthsMm.toString(),
      title: this.title,
      wadiMaxVolume:
        this.typeProperties.type === 'wadi'
          ? this.typeProperties.maxVolume.toString()
          : '',
    };
  }

  toApiReservoir(formValues: ReservoirFormValues): Reservoir {
    return new Reservoir({
      name: this.name,
      uiPosition: this.uiPosition,
      title: formValues.title,
      areaM2: BigInt(formValues.area),
      maxWaterHeightMm: BigInt(formValues.maxWaterHeight),
      minWaterHeightMm: BigInt(formValues.minWaterHeight),
      targetWaterHeight: asFloat(formValues.targetWaterHeight),
      targetWaterHeightGrowingMonths: asFloat(
        formValues.targetWaterHeightGrowingMonths,
      ),
      factorHollow: asInt(formValues.factorHollow) / 100,
      typeProperties: ReservoirModel.formValuesToApiTypeProperties(formValues),
    });
  }

  static getLabelForType(type: ReservoirType, t: TFunction) {
    switch (type) {
      case 'blue_roof':
        return t('reservoirEdit.fields.type.blueRoof');
      case 'green_roof':
        return t('reservoirEdit.fields.type.greenRoof');
      case 'tank':
        return t('reservoirEdit.fields.type.tank');
      case 'infiltration_tank':
        return t('reservoirEdit.fields.type.infiltrationTank');
      case 'pond':
        return t('reservoirEdit.fields.type.pond');
      case 'wadi':
        return t('reservoirEdit.fields.type.wadi');
      default:
        return assertUnreachable(type);
    }
  }

  static getLabelForGreenType(greenType: Reservoir_GreenType, t: TFunction) {
    switch (greenType) {
      case Reservoir_GreenType.DROUGHT_PROOF:
        return t('reservoirEdit.fields.greenType.droughtProof');
      case Reservoir_GreenType.SEDUM:
        return t('reservoirEdit.fields.greenType.sedum');
      case Reservoir_GreenType.BIODIVERSE_SEDUM:
        return t('reservoirEdit.fields.greenType.biodiverseSedum');
      case Reservoir_GreenType.SEED_MIX:
        return t('reservoirEdit.fields.greenType.seedMix');
      case Reservoir_GreenType.GRASSES:
        return t('reservoirEdit.fields.greenType.grasses');
      case Reservoir_GreenType.FULL_GARDEN:
        return t('reservoirEdit.fields.greenType.fullGarden');
      case Reservoir_GreenType.UNSPECIFIED:
        throw new Error('Unsupported green type');
      default:
        return assertUnreachable(greenType);
    }
  }

  static getUnitForParameter(parameter: ReservoirParameter, t: TFunction) {
    switch (parameter) {
      case 'currentWaterLevel':
      case 'minWaterHeight':
      case 'maxWaterHeight':
      case 'targetWaterHeight':
      case 'targetWaterHeightGrowingMonths':
        return 'mm';
      case 'area':
      case 'areaGreen':
        return 'm²';
      case 'reservoirCapacityUsed':
      case 'factorHollow':
        return '%';
      case 'drainageSpeed':
        return t('reservoirEdit.fields.units.drainageSpeed');
      case 'wadiMaxVolume':
        return 'l';
      case 'pondNapOffset':
        return 'm';
      default:
        return '';
    }
  }

  createReservoirParameterDetails(
    t: TFunction,
    waterLevels: WaterLevelsModel,
    isAdmin?: boolean,
    isAutomated?: boolean,
  ): ReservoirParameterDetails[] {
    const currentWaterLevel =
      waterLevels.getWaterLevelByReservoirName(this.name) || 0;

    const canRedirect = !!isAdmin && !!isAutomated;

    const parameterDetails = [
      {
        title: t('reservoirEdit.fields.labels.currentWaterLevel'),
        value: Math.round(currentWaterLevel),
        unit: ReservoirModel.getUnitForParameter('currentWaterLevel', t),
        redirect: canRedirect,
        big: true,
      },
      {
        title: t('reservoirEdit.fields.labels.reservoirCapacityUsed'),
        value: Math.round((100 * currentWaterLevel) / this.maxWaterHeightMm),
        unit: ReservoirModel.getUnitForParameter('reservoirCapacityUsed', t),
        redirect: canRedirect,
        big: true,
      },
      {
        title: t('reservoirEdit.fields.labels.type'),
        value: ReservoirModel.getLabelForType(this.type, t),
      },
      {
        title: t('reservoirEdit.fields.labels.area'),
        value: this.areaBlueM2,
        unit: ReservoirModel.getUnitForParameter('area', t),
      },
    ];

    if (this.typeProperties.type === 'green_roof') {
      parameterDetails.push(
        {
          title: t('reservoirEdit.fields.labels.greenType'),
          value: ReservoirModel.getLabelForGreenType(
            this.typeProperties.greenType,
            t,
          ),
        },
        {
          title: t('reservoirEdit.fields.labels.areaGreen'),
          value: this.typeProperties.areaGreen,
          unit: ReservoirModel.getUnitForParameter('areaGreen', t),
        },
      );
    }
    if (!isAdmin) return parameterDetails;

    parameterDetails.push(
      {
        title: t('reservoirEdit.fields.labels.minWaterHeight'),
        value: this.minWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('minWaterHeight', t),
      },
      {
        title: t('reservoirEdit.fields.labels.maxWaterHeight'),
        value: this.maxWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('maxWaterHeight', t),
      },
      {
        title: t('reservoirEdit.fields.labels.targetWaterHeight'),
        value: this.targetWaterHeightMm,
        unit: ReservoirModel.getUnitForParameter('targetWaterHeight', t),
      },
      {
        title: t('reservoirEdit.fields.labels.targetWaterHeightGrowingMonths'),
        value: this.targetWaterHeightGrowingMonthsMm,
        unit: ReservoirModel.getUnitForParameter(
          'targetWaterHeightGrowingMonths',
          t,
        ),
      },
      {
        title: t('reservoirEdit.fields.labels.factorHollow'),
        value: this.factorHollow,
        unit: ReservoirModel.getUnitForParameter('factorHollow', t),
      },
    );

    if (this.typeProperties.type === 'infiltration_tank') {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.drainageSpeed'),
        value: this.typeProperties.drainageSpeed,
        unit: ReservoirModel.getUnitForParameter('drainageSpeed', t),
      });
    }

    if (this.typeProperties.type === 'pond') {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.pondNapOffset'),
        value: this.typeProperties.napOffset,
        unit: ReservoirModel.getUnitForParameter('pondNapOffset', t),
      });
    }

    if (this.typeProperties.type === 'wadi') {
      parameterDetails.push({
        title: t('reservoirEdit.fields.labels.wadiMaxVolume'),
        value: this.typeProperties.maxVolume,
        unit: ReservoirModel.getUnitForParameter('wadiMaxVolume', t),
      });
    }

    return parameterDetails;
  }
}
