import { listSystemUsers } from '~/pages/SystemUsers/api/systemUsers.api';
import { SystemUsersModel } from '~/pages/SystemUsers/models/SystemUsersModel';
import { makeQuery } from '~/shared/api/internal/utils';

export const listSystemUsersQuery = (systemName: string) =>
  makeQuery({
    queryKey: ['systemUsers', systemName],
    queryFn: async () => {
      const response = await listSystemUsers(systemName);
      return new SystemUsersModel(response);
    },
  });
