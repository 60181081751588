import { ListIssuesResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { EventType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import { IssueModel } from '~/shared/models/issues/IssueModel';

export class IssuesModel {
  readonly issues: IssueModel[];

  readonly total: number;

  readonly nextPageToken: string;

  constructor(issuesResponse: ListIssuesResponse) {
    this.issues = issuesResponse.issues
      // TODO: DS-1165 enable viewing reservoir level issues and remove this filter
      .filter((issue) => issue.type !== EventType.ROOF_DRY)
      .map((issue) => new IssueModel(issue));
    this.total = this.issues.length;
    this.nextPageToken = issuesResponse.nextPageToken;
  }
}
