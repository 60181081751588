import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { SystemsOnlyLoaderData } from '~/pages/SystemDesign/hooks/useSystemsOnly';
import { listSystemsQuery } from '~/shared/api/system.queries';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { createSystemRoute } from '~/shared/models/create-routes';
import { ListSystemsModel } from '~/shared/models/system/ListSystemsModel';
import { assertIsDefined } from '~/types/assert-type';

export const systemDesignLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: LoaderFunctionArgs): Promise<SystemsOnlyLoaderData | Response> => {
    await checkAuthorization(queryClient, request);

    const { systemId } = params;
    assertIsDefined(systemId);

    const listSystemsResponse =
      await queryClient.ensureQueryData(listSystemsQuery);

    const listSystemsModel = new ListSystemsModel(listSystemsResponse);

    const system = listSystemsModel.systemById(systemId);

    assertIsDefined(system);

    if (system.state !== 'ADVICE' && system.state !== 'PRODUCTION_EDIT') {
      return redirect(createSystemRoute(system.id));
    }

    return {
      systems: listSystemsResponse,
    };
  };
