import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';

function getMetricType(typeKey?: string): MetricType | null {
  if (!typeKey) return null;
  return MetricType[typeKey as keyof typeof MetricType] || null;
}

export function toMetricTypeSet(type?: string) {
  const metricType = getMetricType(type);
  return metricType ? new Set<MetricType>([metricType]) : new Set<MetricType>();
}
