import { getCurrentUser } from '~/services/api/fetchers/user.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { UserModel } from '~/shared/models/user/UserModel';

export const currentUserQuery = makeQuery({
  queryKey: ['currentUser'],
  queryFn: async () => {
    const user = await getCurrentUser();
    if (!user) {
      return null;
    }
    return new UserModel(user);
  },
});
