import { useQuery } from '@tanstack/react-query';
import { SystemPermission_Permission } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/user_pb';
import { currentUserQuery } from '~/shared/api/user.queries';
import { hasAcceptedTerms } from '~/shared/auth/auth-utils';
import { useIsSignedIn } from '~/shared/hooks/useIsSignedIn';
import { UserRole } from '~/shared/models/system/RolesModel';
import { UserModel } from '~/shared/models/user/UserModel';
import { assertIsDefined } from '~/types/assert-type';

export function useUser(): UserModel | undefined {
  const isSignedIn = useIsSignedIn();
  const { data: user } = useQuery({ ...currentUserQuery, enabled: isSignedIn });
  return user ?? undefined;
}

export function useRequiredUser() {
  const user = useUser();
  assertIsDefined(user);
  return user;
}

export const useUserPermissions = (): Set<SystemPermission_Permission> => {
  const user = useUser();
  return user?.permissions ?? new Set<SystemPermission_Permission>();
};

export const useHasPermission = (
  requestedUserPermission: SystemPermission_Permission,
): boolean => {
  const permissions = useUserPermissions();
  return permissions.has(requestedUserPermission);
};

export const useHasPermissionForSystem = (
  requestedUserPermissions:
    | SystemPermission_Permission
    | SystemPermission_Permission[],
  systemId: string,
): boolean => {
  const user = useUser();

  const permissions = Array.isArray(requestedUserPermissions)
    ? requestedUserPermissions
    : [requestedUserPermissions];

  return permissions.some(
    (permission) =>
      user?.hasPermissionForSystemId(permission, systemId) ?? false,
  );
};

export const useHasRole = (
  requestedUserRoles: UserRole | UserRole[],
): boolean => {
  const user = useUser();

  const roles = Array.isArray(requestedUserRoles)
    ? requestedUserRoles
    : [requestedUserRoles];

  return roles.some((role) => user?.hasRole(role) ?? false);
};

export const useHasRoleForSystem = (
  requestedUserRoles: UserRole | UserRole[],
  systemId: string,
): boolean => {
  const user = useUser();

  const roles = Array.isArray(requestedUserRoles)
    ? requestedUserRoles
    : [requestedUserRoles];

  return roles.some(
    (role) => user?.hasRoleForSystemId(role, systemId) ?? false,
  );
};

export const useHasAcceptedTerms = () => {
  const user = useUser();

  if (!user) {
    return false;
  }

  return hasAcceptedTerms(user.profile.lastAcceptedTermsAndConditions);
};
