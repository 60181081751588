import {
  ListMetricsRequest_AggregationType,
  ListMetricsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import {
  getWaterLevelMetrics,
  listMetrics,
} from '~/services/api/fetchers/metrics.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { MetricsModel } from '~/shared/models/metrics/MetricsModel';
import { WaterLevelsModel } from '~/shared/models/metrics/waterLevelsModel/WaterLevelsModel';
import { assertIsNonBlankString } from '~/types/assert-type';

export const LIST_METRIC_QUERY_KEY = 'listMetrics';

export const listMetricsQuery = ({
  deviceName,
  metricTypes,
  aggregation,
}: {
  deviceName: string;
  metricTypes: Set<MetricType>;
  aggregation?: ListMetricsRequest_AggregationType;
}) => {
  assertIsNonBlankString(deviceName);
  return makeQuery({
    queryKey: [
      LIST_METRIC_QUERY_KEY,
      {
        deviceName,
        types: Array.from(metricTypes).map((type) => MetricType[type]),
        aggregation,
      },
    ],
    queryFn: async () => {
      if (metricTypes.size === 0) {
        return new MetricsModel(deviceName);
      }
      const metricsResponse = await listMetrics(
        deviceName,
        metricTypes,
        aggregation,
      );
      return new MetricsModel(deviceName, metricsResponse);
    },
    staleTime: 60 * 5_000,
  });
};

export const waterLevelsMetricsQuery = (reservoirNames: string[]) =>
  makeQuery({
    queryKey: ['waterLevelMetrics', reservoirNames],
    queryFn: async () => {
      const waterLevelMetrics = reservoirNames.length
        ? await getWaterLevelMetrics(reservoirNames)
        : new ListMetricsResponse();
      return new WaterLevelsModel(waterLevelMetrics);
    },
  });
