import { keepPreviousData } from '@tanstack/react-query';
import {
  OrderBy,
  OrderBy_AvailableFields,
  OrderBy_SortOrder,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { IssueStatus } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';
import {
  listIssues,
  listIssuesForSystem,
} from '~/services/api/fetchers/issues.api';
import { makeQuery } from '~/shared/api/internal/utils';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import { assertIsNonBlankString } from '~/types/assert-type';

export function createInfinityListIssuesQuery(
  pageSize: number,
  orderBy: OrderBy,
  status: IssueStatus,
) {
  return {
    queryKey: [
      'listIssuesInfinity',
      pageSize,
      OrderBy_AvailableFields[orderBy.field],
      OrderBy_SortOrder[orderBy.sortOrder],
      IssueStatus[status],
    ],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const listIssuesResponse = await listIssues({
        orderBy,
        pageSize,
        pageToken: pageParam,
        status,
      });
      return new IssuesModel(listIssuesResponse);
    },
    getNextPageParam: (lastPage: IssuesModel) => {
      const hasNextPageToken = lastPage?.nextPageToken;
      const hasFetchedEqualPageSize = lastPage?.total === pageSize;
      return hasNextPageToken && hasFetchedEqualPageSize
        ? lastPage.nextPageToken
        : undefined;
    },
    initialPageParam: undefined,
    placeholderData: keepPreviousData,
  };
}

export const listOpenIssuesForSystemQuery = ({
  systemId,
}: {
  systemId: string;
}) => {
  assertIsNonBlankString(systemId);
  return makeQuery({
    queryKey: ['listOpenIssuesForSystem', systemId],
    queryFn: async () =>
      await listIssuesForSystem({
        systemId,
        status: IssueStatus.OPEN,
      }),
  });
};
