import { InfiniteData, QueryClient } from '@tanstack/react-query';
import { OrderBy } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { LoaderFunctionArgs } from 'react-router-dom';
import { createInfinityListIssuesQuery } from '~/shared/api/issues.queries';
import { requireUserRole } from '~/shared/auth/auth-utils';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import {
  DEFAULT_ISSUE_STATUS,
  DEFAULT_ORDER,
  DEFAULT_ORDER_BY,
  DEFAULT_ROWS_PER_PAGE,
} from './issues-constants';
import { RolesModel } from '~/shared/models/system/RolesModel';

export const issuesLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await requireUserRole(queryClient, request, RolesModel.canSeeIssuesList);

    const listIssuesQuery = createInfinityListIssuesQuery(
      DEFAULT_ROWS_PER_PAGE,
      new OrderBy({
        field: DEFAULT_ORDER_BY,
        sortOrder: DEFAULT_ORDER,
      }),
      DEFAULT_ISSUE_STATUS,
    );

    return await queryClient.ensureQueryData({
      queryKey: listIssuesQuery.queryKey,
      queryFn: async (): Promise<InfiniteData<IssuesModel>> => {
        const issuesModel = await listIssuesQuery.queryFn({});
        return {
          pages: [issuesModel],
          pageParams: [undefined],
        };
      },
    });
  };
