import {
  Metric,
  Metric_DataPoint,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { ColorType } from 'highcharts';
import { TFunction } from 'i18next';
import { idFromName } from '~/shared/models/id-utils';
import {
  getOutdatedReadingLabel,
  getSensorValue,
  isMetricReadingOutdated,
} from '~/shared/models/metrics/metrics-utils';
import { assertIsDefined } from '~/types/assert-type';
import {
  getSensorReadingLabel,
  getUnit,
  roundNumericMetric,
} from './metric-conversions';

export type DataPoint = [number, number | null];
export type Data = DataPoint[];

const MINUTE_MS = 60 * 1_000;

function getIntervalCount(differenceMs: number) {
  return Math.round(differenceMs / (10 * MINUTE_MS));
}

function mapDataPoint(dataPoint: Metric_DataPoint): DataPoint {
  const { timestamp } = dataPoint;
  assertIsDefined(timestamp);

  return [timestamp.toDate().getTime(), getSensorValue(dataPoint)];
}

export function getTapStatus(t: TFunction, value: number | undefined): string {
  switch (String(value)) {
    case '0':
      return t('system.sensorReading.tapStatus.TAP_CLOSED');
    case '1':
      return t('system.sensorReading.tapStatus.TAP_OPENED');
    default:
      throw new Error(`Unsupported tap datapoint value ${value}`);
  }
}

// TODO Let's move this getTooltip to MetricModel class
// https://wavin.atlassian.net/browse/DS-1362

type ChartTooltipProps = {
  dataPoint: number | undefined;
  seriesColor: ColorType | undefined;
  seriesName: string;
  t: TFunction;
};

export class MetricModel {
  readonly deviceId: string;

  #chartData: Data = [];

  private initializeChartData: () => void;

  readonly type: MetricType;

  readonly lastDataPoint: DataPoint | undefined = undefined;

  constructor(deviceName: string, metric: Metric) {
    this.deviceId = idFromName(deviceName);
    this.type = metric.type;
    this.initializeChartData = () => {
      metric.dataPoints
        .map((dataPoint) => mapDataPoint(dataPoint))
        .forEach((dataPoint, i, data) => {
          this.addDataPoint(data[i - 1], dataPoint);
        });
      this.initializeChartData = () => {};
    };

    const lastMetricDataPoint = metric.dataPoints.at(-1);

    if (lastMetricDataPoint) {
      this.lastDataPoint = mapDataPoint(lastMetricDataPoint);
    }
  }

  private addDataPoint(
    previousDataPoint: DataPoint | undefined,
    dataPoint: DataPoint,
  ) {
    if (previousDataPoint) {
      this.addMissingData(previousDataPoint[0], dataPoint[0]);
    }
    this.#chartData.push(dataPoint);
  }

  private addMissingData(timeA: number, timeB: number) {
    const differenceMs = timeB - timeA;
    const intervalCount = getIntervalCount(differenceMs);
    if (!intervalCount) {
      return;
    }
    const gapSize = differenceMs / intervalCount;
    const missingValueCount = intervalCount - 1;
    for (const i of Array(missingValueCount).keys()) {
      const timeAOffset = (i + 1) * gapSize;
      this.#chartData.push([timeA + timeAOffset, null]);
    }
  }

  get chartData(): Data {
    this.initializeChartData();
    return this.#chartData;
  }

  getLastValue(t: TFunction): string | number | undefined {
    const lastValue = this.getLastNumberValue();
    if (lastValue === undefined) {
      return undefined;
    }
    if (this.type === MetricType.TAP_ON) {
      return getTapStatus(t, lastValue);
    }
    return lastValue;
  }

  getLastNumberValue(): number | undefined {
    return this.lastDataPoint?.[1] ?? undefined;
  }

  getLatestTimestampLabel(t: TFunction) {
    const { lastDataPoint } = this;

    if (!lastDataPoint) {
      return null;
    }

    if (!isMetricReadingOutdated(lastDataPoint[0])) {
      return null;
    }

    return getOutdatedReadingLabel(t, lastDataPoint[0]);
  }

  getChartTooltip({
    dataPoint,
    seriesColor,
    seriesName,
    t,
  }: ChartTooltipProps) {
    assertIsDefined(dataPoint);
    const value =
      this.type === MetricType.TAP_ON
        ? getTapStatus(t, dataPoint)
        : `${roundNumericMetric(this.type, dataPoint)} ${getUnit(this.type)}`;
    return `
            <span>
              <span style="color:${seriesColor}">\u25CF</span>
                ${seriesName}: <b>${value}</b>
            </span>`;
  }

  getChartLabel({ t, color }: { t: TFunction; color: string }) {
    const label = getSensorReadingLabel(t, this.type);
    return `<span style="color:${color}; font-size: 24px">\u25CF  <span style="color: #353750; font-size: 16px; line-height: 24px">${label}</span></span>`;
  }
}
