import { QueryClient } from '@tanstack/react-query';

import { LoaderFunction } from 'react-router-dom';
import { checkAuthorization } from '~/shared/auth/auth-utils';
import { UserModel } from '~/shared/models/user/UserModel';

export type ProfilePageLoaderResult = {
  user: UserModel;
};

export const profilePageLoader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ request }) => {
    await checkAuthorization(queryClient, request);
    return null;
  };
