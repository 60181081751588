import { Timestamp } from '@bufbuild/protobuf';
import {
  ListIssuesResponse,
  OrderBy,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { IssueStatus } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/issue_pb';
import { aqoraClient } from '~/services/api/aqora-client';

export const listIssues = async ({
  orderBy,
  pageSize,
  pageToken,
  status,
}: {
  orderBy: OrderBy;
  pageSize: number;
  pageToken?: string;
  status: IssueStatus;
}): Promise<ListIssuesResponse> => {
  const now = new Date();
  const lastYear = new Date(now.setFullYear(now.getFullYear() - 1));
  return await aqoraClient.listIssues({
    orderBy: [orderBy],
    pageSize: BigInt(pageSize),
    pageToken,
    rangeFrom: Timestamp.fromDate(lastYear),
    rangeTo: Timestamp.fromDate(new Date()),
    status,
  });
};

export const listIssuesForSystem = async ({
  systemId,
  status,
}: {
  systemId: string;
  status?: IssueStatus;
}): Promise<ListIssuesResponse> => {
  const now = new Date();
  const lastYear = new Date(now.setFullYear(now.getFullYear() - 1));
  return await aqoraClient.listIssues({
    rangeFrom: Timestamp.fromDate(lastYear),
    rangeTo: Timestamp.fromDate(new Date()),
    resourceName: systemId,
    status: status ?? IssueStatus.OPEN,
  });
};
