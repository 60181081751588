import {
  Drain,
  Product,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { TFunction } from 'i18next';
import { TopologyGraph } from '~/shared/models/system/TopologyGraph';
import { idFromName } from '~/shared/models/id-utils';
import { ReservoirModel } from '~/shared/models/system/ReservoirModel';

const DEFAULT_FLOW_RESTRICTION_SPEED = 5;

type ReservoirFormOption = {
  label: string;
  value: string;
  disabled: boolean;
};

export type DrainFormValues = {
  toReservoirName: string | undefined;
  overflowHeight: string;
  flowRestrictionSpeed?: string;
  enableFlowRestriction?: boolean;
};

export class DrainModel {
  readonly id: string;

  readonly name: string;

  readonly fromReservoirId: string;

  readonly fromReservoirName: string;

  readonly toReservoirId: string | undefined;

  readonly toReservoirName: string | undefined;

  readonly productId: string | undefined;

  readonly overflowHeight: number;

  readonly flowRestrictionSpeed?: number;

  constructor(drainResponse: Drain, product?: Product) {
    this.name = drainResponse.name;
    this.id = idFromName(drainResponse.name);
    this.fromReservoirId = ReservoirModel.extractReservoirId(
      drainResponse.fromReservoirName,
    );
    this.fromReservoirName = drainResponse.fromReservoirName;
    this.toReservoirId = drainResponse.toReservoirName
      ? ReservoirModel.extractReservoirId(drainResponse.toReservoirName)
      : undefined;
    this.toReservoirName = drainResponse.toReservoirName || 'sewer';
    this.productId = product ? idFromName(product.name) : undefined;
    this.overflowHeight = drainResponse.overflowHeight;
    this.flowRestrictionSpeed = drainResponse.flowRestrictionSpeed;
  }

  toFormValues(): DrainFormValues {
    return {
      toReservoirName: this.toReservoirName,
      overflowHeight: this.overflowHeight.toString(),
      flowRestrictionSpeed: (
        this.flowRestrictionSpeed ?? DEFAULT_FLOW_RESTRICTION_SPEED
      ).toString(),
      enableFlowRestriction: Boolean(this.flowRestrictionSpeed),
    };
  }

  toApiRequest(formValues: DrainFormValues) {
    const flowRestrictionSpeed =
      formValues.enableFlowRestriction &&
      formValues.flowRestrictionSpeed !== undefined
        ? parseFloat(formValues.flowRestrictionSpeed)
        : undefined;

    return new Drain({
      name: this.name,
      fromReservoirName: this.fromReservoirName,
      toReservoirName:
        formValues.toReservoirName === 'sewer'
          ? undefined
          : formValues.toReservoirName,
      overflowHeight: parseFloat(formValues.overflowHeight),
      flowRestrictionSpeed,
    });
  }

  reservoirsToConnect(
    topologyGraph: TopologyGraph,
    reservoirs: ReservoirModel[],
    t: TFunction,
  ): ReservoirFormOption[] {
    const filteredReservoirs = reservoirs.filter(
      (reservoir) => reservoir.name !== this.fromReservoirName,
    );

    const resevoirOptions = filteredReservoirs.map((reservoir) => {
      const willCreateCycle = topologyGraph.willCreateCycle(
        this.fromReservoirId,
        reservoir.id,
      );

      const cycleWarning = willCreateCycle
        ? ` - ${t('systemDesign.drain.edit.fields.labels.willCreateCycle')}`
        : '';

      return {
        label: reservoir.title + cycleWarning,
        value: reservoir.name,
        disabled: willCreateCycle,
      };
    });

    resevoirOptions.push({
      label: t('systemDesign.drain.edit.fields.labels.sewer'),
      value: 'sewer',
      disabled: false,
    });

    return resevoirOptions;
  }
}
